export const words = ["Actor",
"Gold",
"Painting",
"Grass",
"Parrot",
"Greece",
"Pencil",
"Airport",
"Guitar",
"Piano",
"Hair",
"Pillow",
"Animal",

"Pizza",
"Answer",

"Planet",
"Apple",
"Helmet",
"Plastic",
"Army",
"Holiday",
"Portugal",

"Honey",
"Potato",
"Balloon",
"Horse",
"Queen",
"Banana",
"Hospital",
"Quill",
"Battery",
"House",
"Rain",
"Beach",
"Hydrogen",
"Rainbow",
"Beard",
"Ice",
"Raincoat",
"Bed",
"Insect",

"Belgium",


"Boy",
"Iron",
"River",
"Branch",
"Island",
"Rocket",

"Jackal",
"Room",
"Brother",
"Jelly",
"Rose",
"Camera",

"Russia",
"Candle",
"Jordan",
"Sandwich",
"Car",
"Juice",
"School",
"Caravan",
"Kangaroo",
"Scooter",
"Carpet",
"King",
"Shampoo",
"Cartoon",
"Kitchen",
"Shoe",
"China",
"Kite",
"Soccer",
"Church",
"Knife",
"Spoon",
"Crayon",
"Lamp",
"Stone",
"Crowd",
"Lawyer",
"Sugar",
"Daughter",
"Leather",
"Sweden",
"Death",
"Library",
"Teacher",
"Denmark",
"Lighter",

"Diamond",
"Lion",

"Dinner",
"Lizard",
"Tent",
"Disease",
"Lock",
"Thailand",
"Doctor",
"London",
"Tomato",
"Dog",
"Lunch",

"Dream",
"Machine",
"Traffic",
"Dress",
"Magazine",
"Train",
"Easter",
"Magician",
"Truck",
"Egg",

"Uganda",
"Eggplant",
"Market",
"Umbrella",
"Egypt",
"Match",
"Van",
"Elephant",

"Vase",
"Energy",
"Monkey",

"Engine",
"Morning",
"Vulture",
"England",

"Wall",
"Evening",
"Nail",
"Whale",
"Eye",
"Napkin",
"Window",
"Family",
"Needle",
"Wire",
"Finland",
"Nest",

"Fish",
"Nigeria",
"Yacht",
"Flag",
"Night",
"Yak",
"Flower",
"Notebook",
"Zebra",
"Football",
"Ocean",
"Zoo",
"Forest",
"Oil",
"Garden",
"Fountain",
"Orange",
"Gas",
"France",
"Oxygen",
"Girl",

"Oyster",
"Glass",
"Garage"
]