<template>
  <div class="main-container">
    <img class="wordart" src="@/assets/wordart.png" />
    <div>
      <p>Start New Game</p>
      <button v-on:click="createCodesweepersGame(8, 3)">Easy: 8 x 💣 |  3 x ❤️</button>
      <button v-on:click="createCodesweepersGame(12, 3)">Medium: 12 x 💣 |  3 x ❤️</button>
      <button v-on:click="createCodesweepersGame(20, 2)">Hard: 20 x 💣 |  2 x ❤️</button>
      <button v-on:click="createCodesweepersGame(32, 2)">Impossible: 32 x 💣 |  2 x ❤️</button>
      <button v-on:click="createCodesweepersGame(32, 1)">Insanity: 32 x 💣 |  1 x ❤️</button>
    </div>
    <div>
      <br />
      <p>Join Existing Game</p>
      <form id="join-sweepers-game">
        <input style="padding: 10px; border-radius: 5px; border-width: 0.5px;" type="text" name="sweepersGameId" placeholder="Enter Game ID">
        <button style="margin-left: 10px;" v-on:click="joinCodeSweepersGame()">Join</button>
      </form>
    </div>
  </div>
</template>

<script>
  import { createGame, createCodeSweepersGame } from '@/firebase'
  export default {
    name: 'Welcome',
    mounted() {
      document.title = "CODESWEEPERS"
    },
    methods: {
      createGame() {
        console.log('game created')
        var gameId = createGame()
        this.$router.push(`/game/${gameId}`)
      },
      createCodesweepersGame(bombCount, lifeCount, canadian = false) {
        var gameId = createCodeSweepersGame(canadian, bombCount, lifeCount);
        this.$router.push(`/csgame/${gameId}`)
      },
      joinGame() {
        console.log('joining...')
        const joinForm = document.getElementById('join-game')
        const gameId = joinForm.querySelector('input[name=gameId]').value
        this.$router.push(`/game/${gameId}`)
      },
      joinCodeSweepersGame() {
        console.log('joining...')
        const joinForm = document.getElementById('join-sweepers-game')
        const gameId = joinForm.querySelector('input[name=sweepersGameId]').value
        this.$router.push(`/csgame/${gameId}`)
      }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.main-container {
  display: flex;
  flex-direction: column;
  background-color: lightblue;
  height: 100%;
  width: 100%
}
.wordart {
  max-height: 450px;
  margin-right: auto;
  margin-left: auto;
  margin-top: 5rem
}
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
input {
  animation: fadeIn 1s ease-in;
  transition: 0.8s;
  background: rgba(252,181,0,1);
  box-shadow: 3px 8px rgba(128,38,0,255);
}
button {
  animation: fadeIn 1s ease-in;
  transition: 0.8s;
  margin: 10px 0 0 10px;
  border: none;
  box-shadow: 5px 10px rgba(128,38,0,255);
  background: rgb(253,219,0);
  background: linear-gradient(180deg, rgba(253,219,0,1) 0%, rgba(252,181,0,1) 35%, rgba(253,103,0,1) 100%);
}

button:hover {
  background: linear-gradient(180deg, rgba(253,230,0,1) 35%, rgba(253,103,0,1) 100%, rgba(128,38,0,255) 100% );
  cursor: pointer;
  color: black;
  box-shadow: 1rem;
  transform: scale(1.05);
  transition: 0.3s;
}

@keyframes fadeIn {
  0% {
    opacity:0;
  }
  100% {
    opacity: 1;
  }
}
</style>
