<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: 'App',
}
</script>

<style>
html,
body {
  display: flex;
  margin: 0;
  height: 100%;
  width: 100%;
}
#app {
  background-color: lightblue;
  display: flex;
  height: 100%;
  width: 100%;
  overflow-y:auto;
}
</style>
