export const canadianWords = ["loon",
"loonie",
"twoonie",
"lacrosse",
"hockey",
"aboot",
"trunk",
"hood",
"Canada",
"Toronto",
"geese",
"Ottawa",
"province",
"territory",
"beavor",
"grizzly",
"toque",
"keener",
"housecoat",
"eh",
"beavertail",
"donair",
"two-four",
"26er",
"freezies",
"poutine",
"washroom",
"canuck",
"back bacon",
"eraser",
"whiteout",
"runners",
"sweats",
"sunnies",
"tourtiere",
"mountie",
"KD",
"underduck",
"snow",
"maple",
"zucchini",
"eggplant",
"birch",
"taffy",
"thanksgiving",
"sweater",
"underwear",
"tank-top",
"yard",
"diaper",
"kleenex",
"truck",
"wrench",
"elevator",
"parking lot",
"pavement",
"ketchup",
"fries",
"dessert",
"bangs",
"braid",
"hoser",
"soccer",
"cheque",
"fanny pack",
"garbage",
"canoe",
"moose",
"raccoon",
"queensway",
"tct",
"Cabin",
"plaid",
"penicillin",
"zipper",
"basketball",
"portage",
"camping",
"Canadarm",
"sugarshack",
"S'mores",
"sorry",
"friendly",
"north",
"coastline",
"nickel",
"ice",
"french",
"letterkenny",
"curling"
]
